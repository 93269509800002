import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import Seo from '../components/Seo'
import FailBlock from '../components/mainComponents/FailBlock/FailBlock'

import nfpImage from '../assets/images/jpg/seo/nfp.jpg'

const NotFoundPage = () => {
  const [count, setCount] = useState(7)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCount((prevState) => prevState - 1)
    }, 1000)
    if (!count) navigate('/')

    return () => {
      clearTimeout(timeout)
    }
  }, [count])

  return (
    <>
      <Seo
        title="NOT FOUND"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description="This page is automatically redirected"
        image={nfpImage}
      />
      <FailBlock />
    </>
  )
}

export default NotFoundPage
