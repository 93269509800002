import React from 'react'

import { Link } from 'gatsby'

import * as styles from './styles.module.scss'

import sphere from '../../../assets/images/png/404.png'

const FailBlock = () => (
  <section className={styles.failBlock}>
    <div className={`${styles.failBlockContainer} container`}>
      <div className={styles.failBlockInner}>
        <span>4</span>
        <div className={styles.failBlockImg}>
          <img src={sphere} alt="sphere" />
        </div>
        <span>4</span>
      </div>
      <p className={styles.failBlockSubtitle}>
        Oops! the page you are trying to find is no longer available. Please
        return to the homepage.
      </p>
      <Link to="/" className={styles.failBlockBtn}>
        Home page
      </Link>
    </div>
  </section>
)

export default FailBlock
